/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
} */



/* 폰트 */
/* notosanskr / Nanum gothic Coding / Black Han Sans  */
@import url('//fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url('//fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');
@import url('//fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

/* NanumGothic /  nanumgothic / Nanum Gothic / nanum gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');


* {
  font-family: 'Nanum Gothic', sans-serif;
  /* font-family: 'Noto Sans KR',  sans-serif; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.bold_text { 
  font-family: 'NanumGothic', sans-serif;
  /* font-family: 'Black Han Sans',  monospace; */
  font-weight: 800;
 }

 .highlight_text{
  font-family: 'Nanum Gothic Coding', monospace;
  font-weight: 800;
 }

 .progress-div {
  background-color: #EDEDED;
  border-radius: .5rem;
}

.progress {
  background-color: #81AF39;
  height: 10px;
  border-radius: 1rem;
  transition: 1s ease;
}

.answer_btn:hover{
  background-color: #81AF39;
}

/* 기존 css 옮겨둠 */
#result{
  background-color: #e0e8ff;
  display: none;
  width: 360px;
  margin: 0px auto;
  text-align: center;
  padding-bottom: 30px;
}
#resultImg {
  text-align: center;
}
#resultImg img {
  width: 100%;
}
.loading-image { width: 200px; margin: 0px auto; }


.tab { background-color: #e0e8ff; padding: 4%; }
.tab a { display: inline-block; width: 48%; margin-right: 4%; color: #333; text-decoration: none; }
.tab a:last-child { margin-right: 0px; }
.tab a img { width:100%; border-radius:15px; overflow:hidden; border: 1px solid #fff; box-sizing: border-box;}
.tab a img:hover { border: 2px solid #000; }
.tab_btn { margin-bottom:4%; }
.tab-content { display: none; padding: 10px; background-color: #fff; border-radius:10px; }
.tab-content p { font-size: 14px; line-height: 20px; }
.tab-content:target { display: block; }

/*성향분류표*/
.resulttab { background-color: #ffdd35; padding: 4%; }
.resulttab a { display: inline-block; width: 48%; margin-right: 4%; color: #333; text-decoration: none; }
.resulttab a img { width:100%; border-radius:15px; overflow:hidden; border: 1px solid #fff; box-sizing: border-box;}
.resulttab a:last-child { margin-right: 0px; }
.resulttab a img:hover { border: 2px solid #000; }
.result_title {width: 100%;
  
    overflow: clip;
    margin: 0px auto; 
  text-align: center;
  padding-bottom: 30px; }

.link_list { padding: 4%; }
.link_list img { width:100%; }
.link_list .half:after { display:block; content:''; clear:both; }
.link_list .half a { float: left; width:48%; margin-right:4%; }
.link_list .half a:last-child { margin-right:0px; }
.link_list > div { margin-bottom:10px; }
.link_list > div:last-child { margin-bottom:0px; }
.link_list > div a { display:block; }
.app_down { position:fixed; left:0px; right:0px; bottom: 0px; max-width:360px; margin: 0px auto; }


#result_type { max-width:360px; margin: 0px auto; }
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@-webkit-keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fadeIn{
  animation: fadeIn;
  animation-duration: 0.5s;
}

.fadeOut {
  animation: fadeOut;
  animation-duration: 0.5s;
}


@font-face {
    font-family: 'NanumSquareRound';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
body{
  background-color: #fffbe7;
  text-align: center;
}
.mbti img { margin: 0px auto; }
#main{
  background-color: #6a9cff;
  width: 360px;
  margin: 0px auto;
  text-align: center;
}
#main img {
  width: 100%;
}
p{
  font-size: 20px;
}
/*카운팅*/





/*메인화면 사진에 있는 색
e090b9
edb066
7ec6cc
*/


#qna {
  display: none;
  padding-top: 90px;
  max-width: 360px; margin: 0px auto;
}

.qBox{
  text-align: center;
  font-family: 'NanumSquareRound';
  font-size: 18px;
  margin-bottom: 30px;
}

.qBox span {
  display: block;
  font-family: 'NanumSquareRound';
  font-size: 24px;
  color:#385b00;
  margin-bottom: 15px;
}



.answerList{
  background-color: #FFF;
  border-radius: 40px;
  display: block;
  width: 100%;
  font-size: 15px;
  color: #000;
  border: 1px solid #81af39;
  margin-bottom: 30px;
  height: 80px;
  padding: 0px 10px;
  line-height: 22px;
}
.answerList:last-child {
  margin-bottom: 0px;
}
.answerList:hover, .answerList:focus{
  background-color: #81af39;
  color: white;
}

.status{
  height: 15px;
  width: 100%;
  border-radius: 20px;
  background-color: #ededed;
  margin-block: 40px;
  overflow: hidden;
}

.statusBar{
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  background-color: #81af39;
}